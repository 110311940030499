import { createDeviceChecks, deleteDeviceChecks } from './graphql/mutations';
import { getDeviceChecks } from './graphql/queries';
import { listDeviceChecks } from './graphql/queries';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';

type DeviceCheckPayload = {
  member_id: string;
  time: string;
  responsible: string;
  is_manual_check_sent: boolean;
};

const createDeviceCheck = createAsyncThunk<any, DeviceCheckPayload, { rejectValue: string }>(
  'createDeviceCheck',
  async (payload: DeviceCheckPayload, { rejectWithValue }) => {
    try {
      console.log("Payload: ", payload);
      
      const responseQuery: any = await API.graphql(graphqlOperation(listDeviceChecks, {
        filter: { 
          member_id: { eq: payload.member_id }
        }
      }));

      const existingChecks = responseQuery.data.listDeviceChecks.items
       .filter((item: any) => !item._deleted); 
      // console.log("Existing Checks:", existingChecks);
      if (existingChecks.length >= 5) {

        const earliestCheck = existingChecks.reduce((prev: any, curr: any) => (prev.time < curr.time ? prev : curr));

        const responseGet: any = await API.graphql(graphqlOperation(getDeviceChecks, {
          id: earliestCheck.id,
        }));

        const version = responseGet.data.getDeviceChecks._version;

        const apiName = 'deviceChecksCustomOps';
        const apiEndpoint = '/delete-deviceCheck';
        const body = {
          deviceCheck_id: earliestCheck.id,
          _version: version,
        };
        await API.post(apiName, apiEndpoint, { body });
      }

      // 6. Add the new check
      const deviceCheckDetails = {
        member_id: payload.member_id,
        time: payload.time,
        responsible: payload.responsible,
        is_manual_check_sent: payload.is_manual_check_sent,
      };

      const responseCreate: any = await API.graphql(graphqlOperation(createDeviceChecks, {
        input: deviceCheckDetails
      }));

      const createdDeviceCheck = responseCreate.data.createDeviceChecks;
      console.log("Created Device Check: ", createdDeviceCheck);
      return createdDeviceCheck;

    } catch (error: any) {
      console.log("Real details: ", error);
      return rejectWithValue(`Error on createDeviceCheck: ${error}`);
    }
  },
);

export default createDeviceCheck;
